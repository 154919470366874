import React, { Component } from 'react'
import MUIDataTable, {TableFilterList} from "mui-datatables";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import './admin.css';
import {Link} from 'react-router-dom'

import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';

export default class AdminLeaveHistory extends Component {
    constructor(props){
        super(props)
        const token = localStorage.getItem("token")
        
        let loggedIn = true

        if(token == null){
            loggedIn = false
        }

        this.state = {
          loggedIn,
          iteams: [],
          isLoaded: false,
          req_item:'',
          status:''
        }
    }
 
    async componentDidMount() {
      // POST request using fetch with async/await

      var showdate= new Date();
      var employee_id = sessionStorage.getItem("session_emp_id");
      var month = showdate.getMonth();
      month++;
      var displaydate = showdate.getFullYear() + '-'+ month +'-'+showdate.getDate();

      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ approved_by: employee_id })
      };
      const response = await fetch('http://localhost:8000/api/admin/approveDenyList', requestOptions);
      const data = await response.json();
      // console.log(data.data[1].casual_leave_flag)
      // console.log(data.data[1].sick_leave_flag)
      this.setState({ 
        isLoaded: true,
        postId: data.name,
        iteams: data.data,
        // status: data.data[0].sick_leave_flag,
        req_item: data.data.length,
      });

      // console.log(this.state);
    }

    
    getMuiTheme = () => createTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            '&:nth-child(odd)': { 
              backgroundColor: '#D1D2D4'
            },
            '&:nth-child(even)': {
              backgroundColor: '#E2E3E5'
            } 
          }
        },
        MuiTableCell: {
          head: {
             backgroundColor: "grey !important"
          },
          body:{

          },
        }
      }

    })
    
    render() {

        if(this.state.loggedIn === false){
            return <Redirect to="/" />
        }

        var { iteams, isLoaded, req_item} = this.state;

  
        const columns = [
          { name: "name", label: "Employee Name ",  options:{ filter: true, sort: true} },
          { name: "apply_date", label: "Apply date", options:{ filter: true, sort: true} },
          {name: "leave_strt_date", label: "Leave start date", options:{ filter: true, sort: true} },
          { name: "leave_end_date", label: "Leave end date", options: { filter: true, sort: false} },
          { name: "leave_type", label: "Leave type", options: { filter: true, sort: false} },
          { name: "day_type", label: "Day type", options: { filter: true, sort: false} },
          { name: "description", label: "Description", options: { filter: false, sort: false} },
          { name: "leave_duration", label: "Duration", options: { filter: true, sort: true} },
          { name: "status", label: "Status", options:{ filter: true, sort: false} },
        ];
        const options = {
          filterType: 'dropdown',
          setRowProps: row => { 
            if (row[8] === "Denied") {
              return {
                style: { background: "#ff6666" }
              };
            }
            else if(row[8] === "Accepted"){
              return {
                style: { background: "#99ffd6"}
              }
            }
        },
      }

          const GetLeaveRequData = async () => {
          
          iteams=[];

          var showdate= new Date();
          var employee_id = sessionStorage.getItem("session_emp_id"); 
          var month = showdate.getMonth();
          month++;
          var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();

          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ date: displaydate })
          };
          const response = await fetch('http://localhost:8000/api/admin/leaveApplicationShow', requestOptions);
          const data = await response.json();
          this.setState({ 
            isLoaded: true,
            postId: data.name,
            iteams: data.data
          });  

        //  <div> <Menu/> </div>
        }
  
  
       
        const leave_approve = (leave_id) => {
            // console.log("dipu"+ leave_id)

          var employee_id = sessionStorage.getItem("session_emp_id");

          // console.log("dipu"+ leave_id + "dipu"+ employee_id)

          axios({
            method:'post',
            url:'http://localhost:8000/api/admin/approveLeave/', 
            data: {
              leave_id : leave_id,
              approved_by: employee_id
            }          
          })
          .then(response => {
            if(response.data.success == "1"){
              GetLeaveRequData();
            }
          })
          .catch(error => {
              console.log(error)
          });
        
        }


        const leave_decline = (leave_id) => {
          // console.log("dipu"+ leave_id)

        var employee_id = sessionStorage.getItem("session_emp_id");

       // console.log("dipu"+ leave_id + "dipu"+ employee_id)

        axios({
          method:'post',
          url:'http://localhost:8000/api/admin/ignoreLeave/', 
          data: {
            leave_id : leave_id,
            denied_by: employee_id
          }          
        })
        .then(response => {
          if(response.data.success == "1"){
            GetLeaveRequData();
          }
        })
        .catch(error => {
            console.log(error)
        });
      
      }

      if(!isLoaded){
        return <div>Loading.....</div>
      }

      else{
    

        return (
          
            <div class="wrapper">
                <Header/>
                <Menu/>

                <div className="content-wrapper">                  

                {/* Main content */}
                <section className="content">
                    <h6 className="alert alert-info text-center"> Leave Request </h6>

                    <Link to="/healthIssue">
                      <button className="btn btn-primary btn-sm emp_btn" > Request List</button>
                    </Link>

                    <Link to="/adminLeaveHistory">
                      <button className="btn btn-primary btn-sm emp_btn" disabled="true"> Leave History</button>
                    </Link>



                    {req_item == 0 ? 


                    <p className="alert alert-danger text-center">No leave request till today </p>


                    :
                    <ThemeProvider theme={this.getMuiTheme()}>
                        <MUIDataTable
                          title = {"Today's Leave Employee...  "} 
                          data = {iteams}
                          columns = {columns}
                          options = {options}
                        >

                        </MUIDataTable>
                        </ThemeProvider>

                    // <Table striped bordered hover variant="secondary" className="emp_center">
                    //   <thead>
                    //     <tr>
                    //       <th>Name</th>
                    //       <th>Apply Date</th>
                    //       <th>Leave Start Date</th>
                    //       <th>Leave End Date</th>
                    //       <th>Leave Type</th>
                    //       <th>Day Type</th>

                    //       <th>Description</th>
                    //       <th>Status</th>
                    //     </tr>
                    //   </thead>
                    //   <tbody>
                    //       {iteams.map(item => (
                    //           <tr>
                    //             <td>{item.name}</td>
                    //             <td>{item.apply_date}</td>
                    //             <td>{item.leave_strt_date}</td>
                    //             <td>{item.leave_end_date}</td>
                    //             <td>{item.leave_type}</td>
                    //             <td>{item.day_type}</td>
                    //             <td>{item.description.substring(0,50)}</td>
                    //             <td>{item.status}</td>
                               
                          
                    //           </tr>
                    //         ))}                         
                    //   </tbody>
                    // </Table>

                   }


                  
                </section>
              {/* /.content */}
        </div>







                
                <Footer/>
                
            </div>
        )
      }
    }
}


