import React, { useState, useEffect } from 'react'


import {Link, Redirect} from 'react-router-dom'

import axios from 'axios';

function Menu() {
  const token = localStorage.getItem("token")
  const emp_id = sessionStorage.getItem("session_emp_id")

      
  let loggedIn = true;

  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const [items, setIteams] = useState([]);
  const [value, setValue] = useState(null);
  const [leav_item, setLeavIteam] = useState(null)
  const [req_item, setReqItem] = useState(null)
  const [req_item2, setReqItem2] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)
  
  //accept and denied funciton are placed here
  useEffect(() => {

    axios({
      method:'post',
      url:'http://localhost:8000/api/admin/leaveApplicationShow/', 
      data: {
        date : date             
      }
      
      })
      .then(response => { 
          console.log(response.data.data)
          setIteams(response.data.data)
          // console.log(response.data.data.length)
          setReqItem(response.data.data.length)
          setIsLoaded(true)
      })

      .catch(error => {
          console.log(error)
      });



      fetch('http://localhost:8000/api/admin/registrationRequestShow/')
      
      .then(res => res.json())
      .then(json => {
        setReqItem2(json.data.length)
      }) 

}, []);

  

        return (
          <div>
              <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* Brand Logo */}
                {/* <a href="index3.html" className="brand-link"> */}
              
                  <span className="brand-link logo_custom">
                    <img src="../dist/img/UXBD_logo.png" alt="ULTRA-X Logo" className="brand-image" style={{opacity: '.8'}} />
                    {/* <h3 className="brand-text text-danger text-center">ULTRA-X</h3> */}
                  </span>
                {/* </a> */}
                {/* Sidebar */}
                <div className="sidebar">
                  {/* Sidebar user panel (optional) */}
                  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                      <img src="../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                      {/* <a href="#" className="d-block">Gopal Kumar Saha</a> */}
                      <h5 className="text-light">{localStorage.getItem("emp_name")}</h5>
                    </div>
                  </div>
                  {/* Sidebar Menu */}

      { localStorage.getItem("login_role") == 2  ?

            <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">          
              <li className="nav-header"><h5 className="text-info">Admin Menu   </h5></li>


              <li className="nav-item">
                <a href='http://localhost:3000/employeeSummary' className="nav-link">
                  <i className="nav-icon fas fa-calendar" />
                  <p className="text">Today's Activity <br/>  &nbsp; &nbsp; &nbsp; ( 今日の活動 )</p>
                </a>
              </li>



              <li className="nav-item">
                <a href='http://localhost:3000/monthlyHistory' className="nav-link">
                  <i className="nav-icon far fa-list-alt" />
                  <p className="text">Attendance Summary  <br/>  &nbsp; &nbsp; &nbsp; ( 出席の概要 )</p>
                </a>
              </li>

              {req_item != 0 ?


                  <li className="nav-item">
                    <a href='http://localhost:3000/healthIssue' className="nav-link">
                      <i className="nav-icon fas fa-users" />
                      <p className="text">Leave Request List <br/>  &nbsp;  (リクエストリストを残す)  </p>
                      <span className="badge badge-danger right">{req_item}</span>
                    </a>
                  </li>

                  :

                  <li className="nav-item">
                    <a href='http://localhost:3000/healthIssue' className="nav-link">
                      <i className="nav-icon fas fa-users" />
                      <p className="text">Leave Request List  <br/> &nbsp; (リクエストリストを残す)</p>
                    
                    </a>
                  </li>

              } 


              {req_item2 != 0 ?
                    <li className="nav-item">
                      <a href='http://localhost:3000/request' className="nav-link">
                        <i className="nav-icon fas fa-users" />
                        <p className="text">Registration Request <br/> &nbsp; &nbsp; &nbsp; ( 登録リクエスト )</p>
                        <span className="badge badge-danger right">{req_item2} </span>
                      </a>
                    </li>
              :

                  <li className="nav-item">
                    <a href='http://localhost:3000/request' className="nav-link">
                      <i className="nav-icon fas fa-users" />
                      <p className="text">Registration Request <br/> &nbsp; &nbsp; &nbsp;  ( 登録リクエスト )</p>
                      {/* <span className="badge badge-danger right">3 </span> */}
                    </a>
                  </li>
              }



              <li className="nav-item">
                <a href='http://localhost:3000/homepage/homepage' className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p className="text">Employee List <br/> &nbsp; &nbsp; &nbsp; ( 従業員リスト )</p>
                </a>
              </li>



              <li className="nav-item">
                <a href='#' className="nav-link">
                  <i className="nav-icon far fa-circle text-danger" />
                  <p className="text">Important</p>
                </a>
              </li>

            </ul>





            </nav>

       :

       localStorage.getItem("login_role") == 4  ?
       <nav className="mt-2">
       <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">          
         <li className="nav-header"><h5 className="text-info">Management Menu   </h5></li>


         <li className="nav-item">
           <a href='http://localhost:3000/employeeSummary' className="nav-link">
             <i className="nav-icon fas fa-calendar" />
             <p className="text">Today's Activity</p>
           </a>
         </li>



         <li className="nav-item">
           <a href='http://localhost:3000/monthlyHistory' className="nav-link">
             <i className="nav-icon far fa-list-alt" />
             <p className="text">Attendance Summary</p>
           </a>
         </li>

         {req_item != 0 ?


             <li className="nav-item">
               <a href='http://localhost:3000/leaveRequestPermission' className="nav-link">
                 <i className="nav-icon fas fa-users" />
                 <p className="text">Leave Request</p>
                 <span className="badge badge-danger right">{req_item}</span>
               </a>
             </li>

             :

             <li className="nav-item">
               <a href='http://localhost:3000/leaveRequestPermission' className="nav-link">
                 <i className="nav-icon fas fa-users" />
                 <p className="text">Leave Request Permission</p>
               
               </a>
             </li>

         } 


         {req_item2 != 0 ?
               <li className="nav-item">
                 <a href='http://localhost:3000/request' className="nav-link">
                   <i className="nav-icon fas fa-users" />
                   <p className="text">Registration Request</p>
                   <span className="badge badge-danger right">{req_item2} </span>
                 </a>
               </li>
         :

             <li className="nav-item">
               <a href='http://localhost:3000/request' className="nav-link">
                 <i className="nav-icon fas fa-users" />
                 <p className="text">Registration Request </p>
                 {/* <span className="badge badge-danger right">3 </span> */}
               </a>
             </li>
         }



         <li className="nav-item">
           <a href='http://localhost:3000/homepage/homepage' className="nav-link">
             <i className="nav-icon fas fa-user" />
             <p className="text">Employee List </p>
           </a>
         </li>



         <li className="nav-item">
           <a href='#' className="nav-link">
             <i className="nav-icon far fa-circle text-danger" />
             <p className="text">Important</p>
           </a>
         </li>

       </ul>





       </nav>
       :
       <nav className="mt-2">
       <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">          
         <li className="nav-header"><h5 className="text-info">Employee Menu</h5></li>


         <li className="nav-item">
           <a href='http://localhost:3000/attendence' className="nav-link">
             <i className="nav-icon fas fa-calendar-plus" />
             <p className="text">Attendance</p>
           </a>
       </li>

       

       <li className="nav-item">
           <a href='http://localhost:3000/employeeHealthIssue' className="nav-link">
             <i className="nav-icon fas fa-file-medical" />
             <p className="text">Employee Health</p>
           </a>
       </li>

         
       <li className="nav-item">
           <a href='http://localhost:3000/leaveHistory' className="nav-link">
             <i className="nav-icon fas fa-address-card" />
             <p className="text">Apply Leave</p>
           </a>
       </li>

       <li className="nav-item">
         <a href="http://localhost:3000/applyLeave" className="nav-link">
           <i className="nav-icon far fa-circle text-info" />
           <p>Informational</p>
         </a>
       </li>


         {/* <li className="nav-item">
           <a href="#" className="nav-link">
             <i className="nav-icon far fa-circle text-info" />
             <p>Informational</p>
           </a>
         </li>

         <li className="nav-item">
           <a href="#" className="nav-link">
             <i className="nav-icon far fa-circle text-info" />
             <p>Informational sdgerg</p>
           </a>
         </li> */}
       </ul>
     </nav>

      }




      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
</div>

        )
    }
export default Menu;