import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
           <div>
  <footer className="main-footer text-center">
    <strong>Copyright © 2021 <a href="http://www.uxd.co.jp">Ultra-X BD</a>. </strong>
    All rights reserved.
    <div className="float-right d-none d-sm-inline-block">
          ---
    </div>
  </footer> 
</div>

        )
    }
}
