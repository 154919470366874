import React, { Component } from 'react'

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import { ReactDOM } from 'react-dom';
import "./attendence.css";
import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';



export default class Attendence extends Component {
    constructor(props){
        super(props)
        const token = localStorage.getItem("token")

        let loggedIn = true;


        if(token == null){
            loggedIn = false
        }

        this.state = {
            loggedIn,

            iteams: [],
            isLoaded: false,

            work_start : false,
            lunch_start : true,
            lunch_end : true,
            work_end : true,
            onleave_flag:''
        }
        

    }


   

    async componentDidMount() {
        // POST request using fetch with async/await


        var showdate= new Date();
        var month = showdate.getMonth();
        month++;
        var employee_id = localStorage.getItem("emp_id"); 
        var displaydate = showdate.getFullYear() + '-'+ month +'-'+showdate.getDate();
        // var start_workk = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ emp_id: employee_id, date: displaydate }) 
        };



        const response = await fetch('http://localhost:8000/api/admin/onLeaveOrNot/', requestOptions);
        const data = await response.json();
        // console.log(data.success);
        
        if(data.success == 1){
            // console.log("On Leave");
            this.setState({
                onleave_flag: 1
            })
            
        }


        else{
            // console.log("leave nai vai");
             const response = await fetch('http://localhost:8000/api/users/showEmployeeActivityUser/', requestOptions);
            const data = await response.json();

            this.setState({ 
                isLoaded: true,
                postId: data.name,
                iteams: data.data,
                onleave_flag: 0
            });

            console.log(data.data);

            if(data.data[0].start_work == null && data.data[0].start_lunch == null && data.data[0].end_lunch == null && data.data[0].end_work == null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : false,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :true,
                });
                console.log("4 tai nai");
            }


            else if(data.data[0].start_work != null && data.data[0].end_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :true,
                });
                console.log("sob guloi ase");
            }


            else if(data.data[0].start_work != null && data.data[0].start_lunch != null && data.data[0].end_lunch != null && data.data[0].end_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :true,
                });
                console.log("sob guloi ase");
            }
            

            else if(data.data[0].start_work != null && data.data[0].start_lunch != null && data.data[0].end_lunch != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :false,
                });
                console.log("1,2,3 ase");
            }


            else if(data.data[0].start_work != null && data.data[0].start_lunch != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : true,
                    lunch_start : true,
                    lunch_end : false,
                    work_end :false,
                });
                console.log("1,2, ase");
            }

            else if(data.data[0].start_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,

                    work_start : true,
                    lunch_start : false,
                    lunch_end : true,
                    work_end :false,
                });
                console.log("1 ase");
            }
        }

        
      }



    render() {

        if(this.state.loggedIn === false){
            return <Redirect to="/" />
        }


        var { iteams, isLoaded, work_start, lunch_start, lunch_end, work_end, onleave_flag } = this.state;
       


        const GetAttendanceData = async () => {
            var showdate= new Date();
            var month = showdate.getMonth();
            month++;
            var employee_id = localStorage.getItem("emp_id"); 
            var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();
            // var start_workk = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();


            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ emp_id: employee_id, date: displaydate }) 
            };
            const response = await fetch('http://localhost:8000/api/users/showEmployeeActivityUser/', requestOptions);
            const data = await response.json();

            if(data.data[0].start_work == null && data.data[0].start_lunch == null && data.data[0].end_lunch == null && data.data[0].end_work == null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : false,//able
                    lunch_start : true,//disable
                    lunch_end : true,//disable
                    work_end :true,//disable
                });
                console.log("4 tai nai");
            }


            else if(data.data[0].start_work != null && data.data[0].end_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :true,
                });
                console.log("sob guloi ase");
            }
    

            else if(data.data[0].start_work != null && data.data[0].start_lunch != null && data.data[0].end_lunch != null && data.data[0].end_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :true,
                });
                console.log("sob guloi ase");
            }
            
    
            else if(data.data[0].start_work != null && data.data[0].start_lunch != null && data.data[0].end_lunch != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : true,
                    lunch_start : true,
                    lunch_end : true,
                    work_end :false,
                });
                console.log("1,2,3 ase");
            }
    
    
            else if(data.data[0].start_work != null && data.data[0].start_lunch != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : true,//disable
                    lunch_start : true,//able
                    lunch_end : false,//disable
                    work_end :false,//able
                });
                 console.log("1,2, ase");
            }

            else if(data.data[0].start_work != null){
                this.setState({ 
                    isLoaded: true,
                    postId: data.name,
                    iteams: data.data,
    
                    work_start : true,
                    lunch_start : false,
                    lunch_end : true,//disable
                    work_end :false,//able
                });
                 console.log("1 ase");
            }
    
            
        }


        const GetStartWorkData = () => {
            var showdate= new Date();
            var month = showdate.getMonth();
            month++;
            var employee_id = localStorage.getItem("emp_id"); 
            var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();
            var start_work = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();


            axios({
                method:'post',
                url:'http://localhost:8000/api/users/insertEmployeeActivity_StartWork',
                data: {
                    emp_id: employee_id,
                    date: displaydate,
                    start_work: start_work              
                }  
                
            })
            .then(response => {
                if(response.data.success == 1){
                    // this.setState({
                    //     work_start : true,
                    //     lunch_start : false,
                    //     lunch_end : true,
                    //     work_end : false
                    // })

                    GetAttendanceData();
                    // console.log("start work function call")                    
                }
               
            })
            .catch(err=>console.error(err));


            
        }


        const GetStartLunchData = () => {
            var showdate= new Date();
            var month = showdate.getMonth();
            month++;
            var employee_id = localStorage.getItem("emp_id");
            var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();
            var start_lunch = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();
    
    
            axios({
                method:'post',
                url:'http://localhost:8000/api/users/insertEmployeeActivity_StartLunch/',
                data: {
                    emp_id: employee_id,
                    date: displaydate,
                    start_lunch: start_lunch               
                }
                
            })
            .then(response => {
                if(response.data.success == 1){                   
                    GetAttendanceData();
                }
                
            })
            .catch(err=>console.error(err));
        }


        const GetEndLunchData = () => {
            var showdate= new Date();
            var month = showdate.getMonth();
            month++;
            var employee_id = localStorage.getItem("emp_id");
            var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();
            var end_lunch = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();


            axios({
                method:'post',
                url:'http://localhost:8000/api/users/insertEmployeeActivity_EndLunch/',
                data: {
                    emp_id: employee_id,
                    date: displaydate,
                    end_lunch: end_lunch                
                }
                
            })
            .then(response => {
                if(response.data.success == 1){
                    GetAttendanceData();
                }
               
            })
            .catch(err=>console.error(err));
        }



        const GetEndWorkData = () => {
            var showdate= new Date();
            var month = showdate.getMonth();
            month++;
            var employee_id = localStorage.getItem("emp_id");
            var displaydate = showdate.getFullYear() + '-'+month+'-'+showdate.getDate();
            var end_work = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();
    
    
            axios({
                method:'post',
                url:'http://localhost:8000/api/users/insertEmployeeActivity_EndWork',
                data: {
                    emp_id: employee_id,
                    date: displaydate,
                    end_work : end_work               
                }
                
            })
            .then(response => {
                if(response.data.success == 1){
                    GetAttendanceData();
                }
                
            })
            .catch(err=>console.error(err));
        }


        
        var showdate= new Date();
        var month = showdate.getMonth();
        month++;
        var displaydate = showdate.getDate() + '/'+month+'/'+showdate.getFullYear();
        var displaytime = showdate.getHours() + ':'+showdate.getMinutes()+':'+showdate.getSeconds();
        
        var clockTime = '10:20:30';
        
        setInterval(showTime,1000);
       

        
       
        

        return (
            <div class="wrapper">
                <Header/>
                <Menu/> 

                {/* {console.log("onleave_flag" + onleave_flag)} */}



                <div className="content-wrapper d-flex justify-content-center">                  

                {/* Main content */}
                <section className="content custom_form">
                   
                    { onleave_flag == 1 ? 


                     <Card border="secondary" style={{ width: '40rem' }} className="attendence-content">
                        <Card.Header>
                            <h3 className="text-center">Attendence</h3>
                            <h6 className="text-dark text-center" >Date: {displaydate} <br/> </h6>
                            { <h6 >Time: <span id="clock">10:20:30</span></h6>                         }
                            
                        </Card.Header>

                        <Card.Body className="justify-content-cente">
                            

                            
                        <h5 className="alert alert-danger text-center"> You are on leave today </h5>

                        </Card.Body>
                 </Card>
                     

                    :

                    <Card border="secondary" style={{ width: '40rem' }} className="attendence-content">
                        <Card.Header className="text-center text-danger">
                            <h3 >Attendence</h3>
                            <h6 className="text-danger text-center" >Date: {displaydate} <br/> </h6>
                            { <h6 >Time: <span id="clock">10:20:30</span></h6>                         }
                            
                       </Card.Header>
                        <Card.Body className="justify-content-cente">
                            

                           
                        <Button variant="primary" type="button" class="work_start_button" onClick={e => GetStartWorkData()} className="button_content" disabled={work_start}>
                            Start Work
                        </Button>
                        

                
                        <Button variant="primary" type="button" class="lunch_start_button"  onClick={e => GetStartLunchData()} className="button_content" disabled={lunch_start}>
                            Lunch Start
                        </Button>
                    

                
                        <Button variant="primary" type="button"  class="lunch_end_button"  onClick={e => GetEndLunchData()} className="button_content" disabled={lunch_end}>
                            Lunch End
                        </Button>
                    
                
                
                        <Button variant="primary" type="button"  onClick={e => GetEndWorkData()} className="button_content" disabled={work_end}>
                            End Work
                        </Button>
                               

                            <hr/>
                           
                            <h5 className="text-center">Today's Activity</h5>

                            <Table striped bordered hover size="sm">
                            <thead>
                                <tr>                               
                                    <th>Start Work</th>
                                    <th>Lunch Start</th>
                                    <th>Lunch End</th>
                                    <th>Work End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {iteams.map(item => (
                                    <tr>                                        
                                        <td>{item.start_work}</td>
                                        <td>{item.start_lunch}</td>
                                        <td>{item.end_lunch}</td>
                                        <td>{item.end_work}</td>
                                    </tr>
                                ))} 
                        
                            </tbody>
                            </Table>

                        </Card.Body>
                    </Card>

                   }


                  
                </section>
              {/* /.content */}
                </div>



        {
        
            
        
        }



                
                <Footer/>
                
            </div>
        )

        //setInterval(showTime,1000);
        
        function showTime(){
            let time = new Date();
            let hour = time.getHours();
            let min = time.getMinutes();
            let sec = time.getSeconds();

            hour = hour < 10? "0"+ hour:hour;
            min = min < 10 ? "0"+min: min;
            sec = sec <10 ? "0"+ sec: sec;
            
            let currentTime = hour + ":" + min + ":" + sec;
            clockTime = currentTime;
             //console.log(clockTime);
            document.getElementById('clock').innerHTML = clockTime;
            // return currentTime;

        }
    
    }
}


