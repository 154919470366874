import React, { useState, useEffect } from 'react'
import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
// import {Link, useHistory} from 'react-router-dom'
import { Redirect } from 'react-router';
import './admin.css';
import axios from 'axios';

import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';
import MaterialTable from "material-table";
//import { IconName } from "react-icons/fc";



function Request() {

  const token = localStorage.getItem("token")
  const emp_id = sessionStorage.getItem("session_emp_id")

      
  let loggedIn = true;

  const [items, setIteams] = useState([]);
  const [value, setValue] = useState(null);
  const [leav_item, setLeavIteam] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)
  
  //accept and denied funciton are placed here
  const GetLeaveRequData = () => {
    fetch('http://localhost:8000/api/admin/registrationRequestShow/')

    .then(res => res.json())
    .then(json => {
      // console.log(json);
        setIteams(json.data)
        setLeavIteam(json.data.length)
      // console.log(json.data.length)
  })
  
  window.location.reload(false);
}

const register_accept = (emp_id) => {
  axios({
    method:'post',
    url:'http://localhost:8000/api/admin/registrationRequestApprove', 
    data: {
      emp_id : emp_id
    }          
  })
  .then(response => {
    if(response.data.success == "1"){
      GetLeaveRequData();
    }
  })
  .catch(error => {
      console.log(error)
  });

}


const register_decline = (emp_id) => {
  axios({
    method:'post',
    url:'http://localhost:8000/api/admin/registrationRequestDeny', 
    data: {
      emp_id : emp_id
    }          
  })
  .then(response => {
    if(response.data.success == "1"){
      GetLeaveRequData();
    }
  })
  .catch(error => {
      console.log(error)
  });
}



//material datatable code starts here
const columns = [
  // {
  //   title: "Serial", field: (rowIndex)=> {return rowIndex}, emptyValue: () => <em>null</em>,
  //    hidden: false,
  // },
  {
    title: "Employee name",
    field: "name",
    emptyValue: () => <em>null</em>,
    headerStyle: { textAlign: "left", paddingLeft: "15px" },
  },
  {
    title: "Email",
    field: "email",
    emptyValue: () => <em>null</em>,
    headerStyle: { textAlign: "left", paddingLeft: "15px" },
  },
  {
    title: "Phone number",
    field: "phone",
    emptyValue: () => <em>null</em>,
    headerStyle: { textAlign: "left", paddingLeft: "15px" },
  },
];
// custom export off
// options for showing data in material table
const options = {
  addRowPosition: "first",
  actionsColumnIndex: -1,
  columnsButton: true,
  exportButton: true,
  exportAllData: true,
  filtering: false,
  pageSizeOptions: [5, 10, 20, 50, 100],
  emptyRowsWhenPaging: false,
  paginationType: "stepped",
  jumpToPage: true,
  rowStyle: (data, index) =>
    index % 2 == 0
      ? { background: "#E2E3E5", wordWrap: "break-word" }
      : { background: "#D1D2D4", wordWrap: "break-word" },
  headerStyle: {
    position: "sticky",
    top: "0",
    fontSize: "1.1rem",
    fontWeight: "500",
    width: "0rem",
    paddingLeft: "0px",
    paddingRight: "0px",
    backgroundColor: "#01579b",
    color: "white",
    //backgroundColor: "#039be5",
  },
};
// action required
const actions = [
  (rowData) => ({
    icon: 'add ',
    iconProps: {  fontSize: "medium", color: "primary"  },
    tooltip: "Approve as Employee",
    onClick: (event, rowData) => {
      const flag = window.confirm(
        "Are you want to add " + rowData.name + " as an employee"
      );
      if(flag){
        //register_accept(rowData.emp_id);
        console.log(rowData);
      }
    },
  }),
  (rowData) => ({
    icon: 'delete',
    tooltip: "Decline as Employee",
    iconProps: {  fontSize: "medium", color: 'error'  },
    onClick: (event, rowData) => {
      const flag = window.confirm(
        "Aer you want to denied " + rowData.name + " as an employee"
      );
      if(flag){
        register_decline(rowData.emp_id)
      }
    },
  }),
];

  // const history = useHistory();

  var serial = 0;

  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  


  useEffect(() => {

    fetch('http://localhost:8000/api/admin/registrationRequestShow/')
      
      .then(res => res.json())
      .then(json => {
        // console.log(json.data);
        setIteams(json.data)
        setLeavIteam(json.data.length)
        // console.log(json.data.length)
      })
      .catch(error => {
        console.log(error)
    });   
  }, []);



  


  

        return (
            <div class="wrapper">
                <Header/>
                <Menu/>

                <div className="content-wrapper">                  

                {/* Main content */} 
                <section className="content">
                    <h4 className="text-center">Employee Registration Request </h4>


                    {leav_item == 0 ? 

                          <h5 className="alert alert-info text-center"> No Request till today </h5>
                    :
                        
                    <MaterialTable
                    columns={columns}
                    data={items}
                    title="Employee request list"
                    options={options}
                    actions={actions}
                  />
                      }

                    



                  
                </section>
              {/* /.content */}
        </div>







                
                <Footer/>
                
            </div>
        )
    }
    export default Request;


