import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input, Card }
  from 'react-bootstrap';
import {Link, Redirect} from 'react-router-dom';

import axios from 'axios';



export default class Header extends Component { 
    constructor(props){
      super(props)
      //localStorage.removeItem("token")

      // sessionStorage.clear()

      // sessionStorage.removeItem("token")
    }


    render() {
    

        return (
           <div>
              
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">

            {/* <marquee  direction="left" className="text-info text-center header_content"> <h2>Employee Attandance Management App</h2> </marquee> */}
            
              {/* Left navbar links */}
              <ul className="navbar-nav">
              
                <li className="nav-item">
                  <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
                </li>

                
                <li className="nav-item d-none d-sm-inline-block"> 
                    {/* <Link to="/homepage/homepage"> 
                      <a className="nav-link">Home</a>
                      <button className="btn btn-info">Dashboard</button>
                    </Link> */}
                </li>
              {/* <li className="nav-item d-none d-sm-inline-block">
                <a  className="nav-link">Contact</a>
              </li> */}
              </ul>
    {/* SEARCH FORM */}
    {/* <form className="form-inline ml-3">
      <div className="input-group input-group-sm">
        <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search" />
          </button>
        </div>
      </div>
    </form> */}
    {/* Right navbar links */}

    <ul className="navbar-nav ml-auto">
      {/* <li>Employee Attandance Management </li> */}

      <li className="nav-item">
       
        {/* <Link to="/"> <span className="btn btn-danger" onClick={e => localStorage.removeItem("token")}> Logout</span> </Link> */}
        
        <a href="/" className="btn btn-danger" onClick={e => localStorage.removeItem("token")}> Logout</a>
      </li>
    </ul>
  </nav>
</div>

        )
    }
}
