import React, { useState, useEffect } from 'react'

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import { ReactDOM } from 'react-dom';
import "./attendence.css";
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table, FloatingLabel }
  from 'react-bootstrap';

import MaterialTable from "material-table";
//MUI data table import
// import MUIDataTable, {TableFilterList} from "mui-datatables";
// import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import clsx from "clsx"


function LeaveHistory () {


    const token = localStorage.getItem("token")
    const emp_id = localStorage.getItem("emp_id")

        
    let loggedIn = true;

    const [items, setIteams] = useState([]);
    const [value, setValue] = useState(null);
    const [leav_item, setLeavIteam] = useState(null)

    const [isLoaded, setIsLoaded] = useState(false)
    
    

    const history = useHistory();

    var serial = 0;

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    //material datatable code starts here
  const columns = [
    // {
    //   title: "Serial", field: (rowIndex)=> {return rowIndex}, emptyValue: () => <em>null</em>,
    //    hidden: false,
    // },
    {
      title: "Apply date",
      field: "apply_date",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },

    {
      title: "Leave start date",
      field: "leave_strt_date",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Leave end date",
      field: "leave_end_date",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Duration",
      field: "leave_duration",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Leave type",
      field: "leave_type",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Day type",
      field: "day_type",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Description",
      field: "description",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Status",
      field: "status",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Admin",
      field: "admin_name",
      emptyValue: () => <em>Not accepted yet</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
  ];
  // custom export off
  // options for showing data in material table
  const options = {
    headerStyle: {
      backgroundColor: "grey", //'#80e5ff',
      color: "#000",
    },
    addRowPosition: "first",
    actionsColumnIndex: -1,
    columnsButton: true,
    exportButton: true,
    exportAllData: true,
    filtering: false,
    searchFieldVariant: "outlined",
    pageSizeOptions: [5, 10, 20, 50, 100],
    emptyRowsWhenPaging: false,
    paginationType: "stepped",
    jumpToPage: true,
    rowStyle: (data, index) =>
      index % 2 == 0
        ? { background: "#E2E3E5", wordWrap: "break-word" }
        : { background: "#D1D2D4", wordWrap: "break-word" },
        cellStyle: (data, rowData) => 
        rowData.status == "Approved"
        ? {background: '#80ff80'}
        : (rowData.status == "Denied"
        ? {background: '#ff9980'}
        : null),
    headerStyle: {
      position: "sticky",
      top: "0",
      fontSize: "1.1rem",
      fontWeight: "500",
      width: "0rem",
      paddingLeft: "0px",
      paddingRight: "0px",
      backgroundColor: "#01579b",
      color: "white",
      //backgroundColor: "#039be5",
    },
  };
  //no action required


    useEffect(() => {

      axios({
        method:'post',
        url:'http://localhost:8000/api/users/showLeaveStatus/', 
        data: {
            emp_id : emp_id             
        }
        
        })
        .then(response => { 
            // console.log(response.data.data)
            setIteams(response.data.data)
            // console.log(response.data.data.length)
            setLeavIteam(response.data.data.length)
           
            setIsLoaded(true)
        })

        .catch(error => {
            console.log(error)
        });

  }, []);




    if(today.getDate()<10){
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + '0'+ today.getDate();
    }



    if(token == null){
        loggedIn = false
    }


    if(loggedIn === false){
        return <Redirect to="/" />
    }



    if(isLoaded === false){
      return <h4 className="alert alert-info text-center">Loading....</h4>
    }

    else{

      return (   
          <div className="wrapper">
              <Header/>
              <Menu/>

              <div className="content-wrapper ">

                  <section className="content ">


                      <Link to="/leaveHistory">
                      <button className="btn btn-primary btn-sm emp_btn" disabled="true"> Leave History </button>
                      </Link>
                      <Link to="/applyLeave">
                      <button className="btn btn-primary btn-sm emp_btn" > Casual Leave Apply</button>
                      </Link>
                      <Link to="/sickLeaveApply">
                      <button className="btn btn-primary btn-sm emp_btn" > Sick Leave Apply</button>
                      </Link>

                      <div className="d-flex justify-content-center">
                          <Card border="danger" style={{ width: '100rem' }}>
                              <Card.Header><h3 className="text-center text-danger">Leave History (歴史を残す)</h3></Card.Header>
                              <Card.Body>

                                {leav_item == 0 ? 

                                  <h4 className="alert alert-info text-center"> No leave history available </h4>
                                :
                                  <MaterialTable
                                    columns={columns}
                                    data={items}
                                    title="Today's employee activity"
                                    options={options}
                                  
                                  />
                                  // <Table striped bordered hover variant="light">
                                  //   <thead>
                                  //     <tr>
                                  //       <th>Serial <br/> (シリアル)</th>
                                  //       <th>Apply Date <br/> ( 適用日 )</th>
                                  //       <th>Leave Start Date <br/> ( 開始日を残す )</th>
                                  //       <th>Leave End Date <br/> ( 終了日を残す )</th>
                                  //       <th>Duration <br/> (間隔)</th>   
                                  //       <th>Leave Type <br/> (タイプを残す)</th>
                                  //       <th>Day Type <br/> (日タイプ)</th>
                                  //       <th>Description <br/> (説明)</th>
                                  //       <th>Status <br/> (状態)</th>  
                                  //       <th>Admin Name <br/> (管理者名)</th>                            
                                  //     </tr>
                                  //   </thead>
                                  //   <tbody>
                                  //       {items.map(items => (
                                  //         <tr>
                                  //           <td>{serial +=1 } </td>
                                  //           <td>{items.apply_date} </td>
                                  //           <td>{items.leave_strt_date}</td>
                                  //           <td>{items.leave_end_date}</td>
                                  //           <td>{items.leave_duration}</td> 
                                  //           <td>{items.leave_type}</td> 
                                  //           <td>{items.day_type}</td> 
                                  //           <td>{items.description}</td>  
                                  //           <td>{items.status}</td>  
                                  //           <td>{items.admin_name}</td>                                      
                                  //         </tr>
                      
                                  //       ))}
                                  //   </tbody>
                                  // </Table>
                                }

                                  
                              </Card.Body>
                          </Card>
                      </div>


                  </section>

              </div>

              <Footer/>
          </div>
      );
    }
}

export default LeaveHistory ;