import React, { Component } from 'react'

import Header from './Header';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Footer from './Footer';
import { Redirect } from 'react-router';


import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';

export default class Homepage extends Component {
    constructor(props){
        super(props)
        const token = localStorage.getItem("token")

        let loggedIn = true

        if(token == null){
            loggedIn = false
        }

        this.state = {
            loggedIn
        }
    }

    
    render() {
        if(this.state.loggedIn === false){
            return <Redirect to="/" />
        }

        return (
            <div class="wrapper">
                <Header/>
                <Menu/>
                <Dashboard/>
                <Footer/>
                
            </div>
        )
    }
}
