import './App.css';

import Homepage from './Components/homepage/homepage';
import Login from './Components/login/login';
import Register from './Components/register/register';
import Employeesummary from './Components/Admin/employeeSummary';
import Monthlyhistory from './Components/Admin/monthlyHistory';
import Healthissue from './Components/Admin/healthIssue';
import Request from './Components/Admin/request';
import LateEmployee from './Components/Admin/lateEmployee';
import AbsentEmployee from './Components/Admin/absentEmployee';
import LeaveEmployee from './Components/Admin/leaveEmployee'; 

import DailyHealthIssue from './Components/Admin/dailyHealthIssue';
import AdminLeaveHistory from './Components/Admin/adminLeaveHistory';

import Attendence from './Components/Employee/attendence';
import CasualLeaveApply from './Components/Employee/applyLeave'; 
import EmployeeHealthIssue from './Components/Employee/employeeHealthIssue';
import SickLeaveApply from './Components/Employee/sickLeaveApply';  
  
import LeaveHistory from './Components/Employee/leaveHistory';


import LeaveRequestPermission from './Components/Manager/leaveRequestPermission';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


import { Button, Form, FormGroup, Label, Input, Card }
  from 'react-bootstrap';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";






function App() {
  return (
    <div className="App">
       
      <Router>
          <Switch>
              


              <Route  path="/homepage">
                <Homepage />
              </Route>

              <Route  path="/login">
                <Login />
              </Route>

              <Route  path="/register">
                <Register />
              </Route>

              <Route  path="/employeeSummary">
                <Employeesummary />
              </Route>

              <Route  path="/monthlyHistory">
                <Monthlyhistory />
              </Route>

              <Route  path="/healthIssue">
                <Healthissue />
              </Route>
              <Route  path="/request">
                <Request />
              </Route>

              <Route  path="/attendence">
                <Attendence />
              </Route>

              <Route  path="/employeeHealthIssue">
                <EmployeeHealthIssue />
              </Route>

              <Route  path="/lateEmployee">
                <LateEmployee />
              </Route>

              <Route  path="/absentEmployee">
                <AbsentEmployee />
              </Route>

              <Route  path="/leaveEmployee">
                <LeaveEmployee />
              </Route>

              <Route  path="/applyLeave">
                <CasualLeaveApply />
              </Route>

              <Route  path="/dailyHealthIssue">
                <DailyHealthIssue />
              </Route>
              
              <Route  path="/leaveHistory">
                <LeaveHistory />
              </Route> 

              <Route  path="/adminLeaveHistory">
                <AdminLeaveHistory />
              </Route>

             

              <Route  path="/sickLeaveApply">
                <SickLeaveApply />
              </Route> 


              <Route  path="/leaveRequestPermission">
                <LeaveRequestPermission />
              </Route> 

              

              <Route  path="/"> <Login /> </Route>
          </Switch>
      </Router>

    </div>
  );
}

export default App;
