import React, { useState, useEffect } from 'react'

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
// import '../Admin/admin.css';
import {Link} from 'react-router-dom'

import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';

function LeaveRequestPermission() {
  const token = localStorage.getItem("token")
  const emp_id = sessionStorage.getItem("session_emp_id")

      
  let loggedIn = true;

  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const [items, setIteams] = useState([]);
  const [value, setValue] = useState(null);
  const [leav_item, setLeavIteam] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)
  
  //accept and denied funciton are placed here
  useEffect(() => {

    axios({
      method:'post',
      url:'http://localhost:8000/api/admin/leaveApplicationShow/', 
      data: {
        date : date             
      }
      
      })
      .then(response => { 
          console.log(response.data.data)
          setIteams(response.data.data)
          // console.log(response.data.data.length)
          setLeavIteam(response.data.data.length)
         
          setIsLoaded(true)
      })

      .catch(error => {
          console.log(error)
      });

}, []);


const GetLeaveRequData = async () => {
  axios({
    method:'post',
    url:'http://localhost:8000/api/admin/leaveApplicationShow/', 
    data: {
      date : date             
    }
    
    })
    .then(response => { 
        console.log(response.data.data)
        setIteams(response.data.data)
        // console.log(response.data.data.length)
        setLeavIteam(response.data.data.length)
       
        setIsLoaded(true)
    })

    .catch(error => {
        console.log(error)
    });
  window.location.reload(false);
}
  
const leave_approve = (leave_id) => {

    var employee_id = localStorage.getItem("emp_id");
    axios({
      method:'post',
      url:'http://localhost:8000/api/admin/approveLeave/', 
      data: {
        leave_id : leave_id,
        approved_by: employee_id
      }          
    })
    .then(response => {
      if(response.data.success == "1"){
        GetLeaveRequData();
      }
    })
    .catch(error => {
        console.log(error)
    });

}



const leave_decline = (leave_id) => {
  
    var employee_id = localStorage.getItem("emp_id");
    axios({
      method:'post',
      url:'http://localhost:8000/api/admin/ignoreLeave/', 
      data: {
        leave_id : leave_id,
        denied_by: employee_id
      }          
    })
    .then(response => {
      if(response.data.success == "1"){
        GetLeaveRequData();
      }
    })
    .catch(error => {
        console.log(error)
    });

}


    
        return (
          
            <div class="wrapper">
                <Header/>
                <Menu/>

                <div className="content-wrapper">                  
                <section className="content">
                    <h4 className="text-center">Employee Leave Request </h4>


                    {leav_item == 0 ? 

                          <h5 className="alert alert-info text-center"> No Request till today </h5>
                    :
                        
                      <Table striped bordered hover variant="secondary" className="emp_center">
                        <thead>
                          <tr>
                            <th>Apply Date</th>
                            <th>Employee Name</th>
                            <th>Email</th>
                            <th>Leave Start</th>
                            <th>Leave End</th>
                            <th>Type</th>
                            <th>Day Type </th>
                            <th>Duration</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            {items.map(items => (
                                <tr>
                                  <td>{items.apply_date}</td>
                                  <td>{items.name}</td>
                                  <td>{items.email}</td>
                                  <td>{items.leave_strt_date}</td>
                                  <td>{items.leave_end_date}</td>

                                  {items.casual_leave_flag == 1 ? <td>Casual</td>: <td>Sick</td>}

                                  <td>{items.day_type}</td>
                                  <td>{items.leave_duration}</td>
                                  <td>{items.description}</td>
                                  <td>
                                    <button className="btn btn-success btn-sm emp_leav_req_btn" onClick={e=>leave_approve(items.leave_id)}>Approve </button>
                                    <button  className="btn btn-danger btn-sm emp_leav_req_btn" onClick={e=>leave_decline(items.leave_id)}>Decline </button>
                                  </td>
                            
                                </tr>
                              ))}                         
                        </tbody>
                    </Table>
                    }

                    



                  
                </section>
                </div>







                
                <Footer/>
                
            </div>
        )
      }
      export default LeaveRequestPermission;



