import React, { useState, useEffect } from 'react'
import './register.css'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { Button, Form, FormGroup, Label, Input, Card }
  from 'react-bootstrap';

import {Link, Redirect, useHistory} from 'react-router-dom';

import axios from 'axios';

function Registration() {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [con_password, setConPassword] = useState(null);


    const [name_err, setName_Err] = useState(null);
    const [pass_err, setPass_Err] = useState(null);
    const [success_msg, setSuccessMsg] = useState(null);

    const history = useHistory();

    


    async function submit_registration()
    {
        // console.log(name, email, phone, password, con_password)

        var letters = /^[A-Za-z]+$/;

        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if(name != null){
            if(name.match(letters)){
                console.log("ok")
                if(password == con_password){
                    console.log("pass ok")
                    axios({
                        method:'post',
                        url:'http://localhost:8000/api/users/registration/',
                        data: {
                            name: name,
                            email : email,
                            phone: phone,
                            password : password
                        }
                        
                    })
                    .then(response => {
                        if(response.data.success == 1){
                            setSuccessMsg(response.data.message)
                            // window.location.reload(false);
                        }
                        else{
                            setSuccessMsg(response.data.message)
                        }
                    })
                    .catch(err=>console.error(err));
                }
                else{
                    setPass_Err("Password and Confirm password is no matched.");
                }
            }
            else{
                setName_Err("Name must alphabatic.");
            }
        }
        
    }


    if(name_err){
        var name_error = (
            <p className="text-danger" role="alert">* {name_err}</p>
        )
    }
    if(pass_err){
        var pass_error = (
            <p className="text-danger" role="alert">* {pass_err}</p>
        )
    }

    if(success_msg){
        var success_message = (
            <p className="text-danger" role="alert">* {success_msg}</p>
        )
    }
    
        
        return (
            <div className="wrapper">
                <div className="d-flex justify-content-center registration-content">                              
                    <Card border="primary" className="bg-light" style={{ width: '35rem' }}>                   
                        <Card.Header className="bg-primary text-center"> <h3>Registration (登録)</h3> </Card.Header>
                                                        
                        <Card.Body>
                            {success_message}
                            <Form> {console.log("here"+ name_error)}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name (名前) <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="text" required onChange={(e)=>setName(e.target.value)}/>                                
                                </Form.Group>
                                {name_error}
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address (電子メールアドレス) <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="email"  required onChange={(e)=>setEmail(e.target.value)}/>                                
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Phone (電話) <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="tel" required onChange={(e)=>setPhone(e.target.value)} />                                
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password (パスワード) <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="password" required onChange={(e)=>setPassword(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Confirm Password (パスワードを認証する) <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="password" required onChange={(e)=>setConPassword(e.target.value)}/>
                                </Form.Group>
                                {pass_error}

                                <Button type="submit" className="btn btn-primary" onClick={submit_registration}>
                                Submit (送信)
                                </Button>
                                

                                <br/>
                                <p>If you already have an account, please  (すでにアカウントをお持ちの場合は)  <Link to="/" className="custom-link"> log in (ログインする) </Link> </p>
                            </Form>
                        </Card.Body>
                    </Card>              
                </div>
            </div>
            
        )
    
}
export default Registration;
