import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import { ReactDOM } from 'react-dom';
import "./attendence.css";
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table, FloatingLabel }
  from 'react-bootstrap';





function SickLeaveApply() {


    const token = localStorage.getItem("token")
    var emp_id =localStorage.getItem("emp_id");
        
    let loggedIn = true, login_err = "", error="";

    const [start_date, setStart_Date] = useState(null);
    const [end_date, setEnd_Date] = useState(null);
    const [day_type, setDayType] = useState(null);
    const [description, setDescription] = useState(null);
    const [medical_certificate, setMedical_certificate] = useState(null);

    const history = useHistory();


    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    // console.log(date);

    if(today.getDate()<10){
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + '0'+ today.getDate();
        // console.log(date);
    }



    if(token == null){
        loggedIn = false
    }


    if(loggedIn === false){
        return <Redirect to="/" />
    }

    async function apply_sickleave()
    {
        console.log("ok vai")


        if(start_date != null && end_date != null){
            const date1 = start_date.toISOString().substring(0,10);
            const date2 = end_date.toISOString().substring(0,10);



            console.log(date1 , date2)

        

            console.log(day_type)

            if(day_type == 0){
                console.log("morning half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 1,
                        evening_leave_flag: 0,
                        full_day_flag: 0,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                       
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }

            else if(day_type == 1)
            {
                console.log("evenibg half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 0,
                        evening_leave_flag: 1,
                        full_day_flag: 0,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                        
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }
            else if(day_type == 2)
            {
                console.log("full half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 0,
                        evening_leave_flag: 0,
                        full_day_flag: 1,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                       
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }

        }










    }


    

    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
      };


    return (

        
        <div className="wrapper">
            <Header/>
            <Menu/>

            <div className="content-wrapper ">

                <section className="content ">


                    <Link to="/leaveHistory">
                    <button className="btn btn-primary btn-sm emp_btn" > Leave History </button>
                    </Link>
                    <Link to="/applyLeave">
                    <button className="btn btn-primary btn-sm emp_btn" > Casual Leave Apply</button>
                    </Link>
                    <Link to="/sickLeaveApply">
                    <button className="btn btn-primary btn-sm emp_btn" disabled="true"> Sick Leave Apply</button>
                    </Link>

                    <div className="d-flex justify-content-center">
                        <Card border="danger" style={{ width: '50rem' }}>
                            <Card.Header><h3 className="text-center text-danger">Apply for Sick Leave (病気休暇を申請する)</h3></Card.Header>
                            <Card.Body>

                            <Form encType = "multipart/form-data">
                                {console.log(start_date, end_date, day_type, description, medical_certificate)}


                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave Start Date( 開始日を残す ) <span className="text-danger"> *</span></Form.Label>
                                        
                                            <DatePicker selected={start_date} 
                                                onChange={(date) => setStart_Date(date)}
                                                dateFormat='yyyy/MM/dd'
                                                disabledDays={[ { daysOfWeek: [0, 6] }]}
                                                isClearable
                                                filterDate={isWeekday}
                                                minDate={new Date()}
                                            />
                                       
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave End Date ( 終了日を残す ) <span className="text-danger"> *</span></Form.Label>
                                        
                                            <DatePicker selected={end_date} 
                                                onChange={(date) => setEnd_Date(date)}
                                                dateFormat='yyyy/MM/dd'
                                                disabledDays={[ { daysOfWeek: [0, 6] }]}
                                                isClearable
                                                filterDate={isWeekday}
                                                minDate={new Date()}
                                            />
                                       
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Day Type (日タイプ)<span className="text-danger"> *</span></Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                               
                                                label="Morning Half"
                                                name="sick_day"
                                                type={type}
                                                value="0"
                                                onChange={(e)=>setDayType(e.target.value)}
                                                id="0"
                                               
                                                required
                                            />
                                            <Form.Check
                                              
                                                label="Evening Half"
                                                name="sick_day"
                                                value="1"
                                                type={type}
                                                onChange={(e)=>setDayType(e.target.value)}
                                                id="1"
                                               
                                                required
                                            />

                                            <Form.Check
                                            
                                                label="Full Day"
                                                name="sick_day"
                                                value="2"
                                                type={type}
                                                onChange={(e)=>setDayType(e.target.value)}
                                                id="3"
                                                
                                                required
                                            />          
                                    
                                            </div>
                                        ))}
                                    </Form.Group>


                                    


                                    <Form.Label>Description (説明)<span className="text-danger"> *</span></Form.Label>
                                    
                                    <FloatingLabel controlId="floatingTextarea" className="mb-3">
                                        <Form.Control as="textarea" placeholder="Leave a comment here" onChange={(e)=>setDescription(e.target.value)}  required/>
                                    </FloatingLabel>



                                    <Form.Group controlId="formFile" className="mb-3">
                                        <Form.Label>Medical Certificate (診断書) </Form.Label>
                                        <Form.Control type="file" onChange={(e)=>setMedical_certificate(e.target.value)}  required />
                                    </Form.Group>
                                
                                <Button variant="primary"  onClick={apply_sickleave}>
                                    Submit (送信)
                                </Button>
                            </Form>

                                
                            </Card.Body>
                        </Card>
                    </div>


                </section>

            </div>















            <Footer/>
        </div>
    );
}

export default SickLeaveApply;