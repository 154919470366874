import React, { useState, useEffect } from 'react'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import { ReactDOM } from 'react-dom';
import "./attendence.css";
import {Link, useHistory} from 'react-router-dom'
import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table, FloatingLabel }
  from 'react-bootstrap';





function CasualLeaveApply() {


    const token = localStorage.getItem("token")
    var emp_id =localStorage.getItem("emp_id");
    let loggedIn = true;

    const [start_date, setStart_Date] = useState("");
    const [end_date, setEnd_Date] = useState("");
    const [day_type, setDay_Type] = useState("");
    const [check_leader, setCheckLeader] = useState("");
    const [check_project_manager, setCheckProjectManager] = useState("");
    const [description, setDescription] = useState("");


    const history = useHistory();



    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();


    if(today.getDate()<10){
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + '0'+ today.getDate();
    }



    if(token == null){
        loggedIn = false
    }


    if(loggedIn === false){
        return <Redirect to="/" />
    }




    async function apply_leave()
    {

        function convertDate( date) {
            let offset = new Date().getTimezoneOffset();
            let DATE = new Date(date).getTime()/1000 - (offset*60);
            const day = new Date(DATE*1000).toISOString().substring(0,10);
            //console.log("date = "+ day);
            return new Date(DATE* 1000).toISOString().substring(0,10);
        }
        console.log( start_date, end_date, day_type, check_leader, check_project_manager, description)

        if(start_date != null && end_date != null){
            const date1 = convertDate(start_date.toISOString());
            const date2 = convertDate(end_date.toISOString());



            console.log(date1 , date2)

        

            console.log(day_type)

            if(day_type == 0){
                console.log("morning half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 1,
                        evening_leave_flag: 0,
                        full_day_flag: 0,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                        check_with_leader: check_leader
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }

            else if(day_type == 1)
            {
                console.log("evenibg half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 0,
                        evening_leave_flag: 1,
                        full_day_flag: 0,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                        check_with_leader: check_leader
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }
            else if(day_type == 2)
            {
                console.log("full half")
                axios({ 
                    method:'post',
                    url:'http://localhost:8000/api/users/insertLeave/',
                    data: {
                        emp_id: emp_id,
                        apply_date : date,
                        leave_start_date: date1,
                        leave_end_date : date2,
                        morning_leave_flag: 0,
                        evening_leave_flag: 0,
                        full_day_flag: 1,
                        casual_leave_flag: 1,
                        sick_leave_flag: 0,
                        description: description,
                        check_with_leader: check_leader
                    }
                    
                })

                .then(response => { 
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error)
                });
            }

        }

        

        



        // axios({ 
        //     method:'post',
        //     url:'http://localhost:8000/api/users/insertLeave/',
        //     data: {
        //         emp_id: emp_id,
        //         apply_date : date,

        //         leave_start_date: date1,
        //         leave_end_date : date2,
        //         morning_leave_flag: 1,
        //         evening_leave_flag: 0,
        //         full_day_flag: 0,
        //         casual_leave_flag: 1,
        //         sick_leave_flag: 0,
        //         description: description
        //     }
            
        // })

        // .then(response => { 
        //     console.log(response.data)
        // })
        // .catch(error => {
        //     console.log(error)
        // });
    }

    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
      };

    return (

        
        <div className="wrapper">
            <Header/>
            <Menu/>

            <div className="content-wrapper ">

                <section className="content ">


                    <Link to="/leaveHistory">
                    <button className="btn btn-primary btn-sm emp_btn" > Leave History </button>
                    </Link>
                    <Link to="/applyLeave">
                    <button className="btn btn-primary btn-sm emp_btn" disabled="true">Casual Leave Apply </button>
                    </Link>
                    <Link to="/sickLeaveApply">
                    <button className="btn btn-primary btn-sm emp_btn" > Sick Leave Apply</button>
                    </Link>

                    <div className="d-flex justify-content-center">
                        <Card border="secondary" style={{ width: '50rem' }}>
                            <Card.Header><h3 className="text-center text-danger">Apply for Casual Leave (カジュアル休暇を申請する)</h3></Card.Header>
                            <Card.Body>

                            <Form>
                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)}/>
                                    
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Enter Password" onChange={(e)=>setPassword(e.target.value)}/>
                                </Form.Group> */}


                                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave Start (スタートを離れる) <span className="text-danger"> *</span></Form.Label> 
                                        <Form.Control type="date"  min={date} required onChange={(e)=>setStart_Date(e.target.value)}/> 
                                    </Form.Group> */}
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave Start Date( 開始日を残す ) <span className="text-danger"> *</span></Form.Label>
                                        
                                            <DatePicker selected={start_date} 
                                                onChange={(date) => setStart_Date(date)}
                                                dateFormat='yyyy/MM/dd'
                                                disabledDays={[ { daysOfWeek: [0, 6] }]}
                                                isClearable
                                                filterDate={isWeekday}
                                                minDate={new Date()}
                                                required
                                            />
                                       
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave End (終了を残す)<span className="text-danger"> *</span></Form.Label>
                                        <Form.Control type="date" min={date}  required onChange={(e)=>setEnd_Date(e.target.value)}/>
                                    </Form.Group> */}

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Leave End Date ( 終了日を残す ) <span className="text-danger"> *</span></Form.Label>
                                        
                                            <DatePicker selected={end_date} 
                                                onChange={(date) => setEnd_Date(date)}
                                                dateFormat='yyyy/MM/dd'
                                                disabledDays={[ { daysOfWeek: [0, 6] }]}
                                                isClearable
                                                filterDate={isWeekday}
                                                minDate={new Date()}
                                                required
                                            />
                                       
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Day Type (日タイプ)<span className="text-danger"> *</span></Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                               
                                                label="Morning Half"
                                                name="day_type"
                                                type={type}
                                                value="0"
                                            
                                                id="0"
                                                onChange={(e)=>setDay_Type(e.target.value)}
                                                required
                                            />
                                            <Form.Check
                                              
                                                label="Evening Half"
                                                name="day_type"
                                                value="1"
                                                type={type}
                                               
                                                id="1"
                                                onChange={(e)=>setDay_Type(e.target.value)}
                                                required
                                            />

                                            <Form.Check
                                            
                                                label="Full Day"
                                                name="day_type"
                                                value="2"
                                                type={type}
                                               
                                                id="3"
                                                onChange={(e)=>setDay_Type(e.target.value)}
                                                required
                                            />          
                                    
                                            </div>
                                        ))}
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Do you checked with leader? (リーダーに確認しましたか？)<span className="text-danger"> *</span></Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                
                                                label="Yes"
                                                name="check_leader"
                                                value="1"
                                                type={type}
                                                
                                                id="4"
                                                onChange={(e)=>setCheckLeader(e.target.value)}
                                                required
                                            />
                                            <Form.Check
                                                
                                                label="No"
                                                name="check_leader"
                                                value="0"
                                                type={type}
                                               
                                                id="5"
                                                onChange={(e)=>setCheckLeader(e.target.value)}
                                                required
                                            />
                                    
                                            </div>
                                        ))}
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Do you checked with Project Manager? (プロジェクトマネージャーに確認しましたか？) <span className="text-danger"> *</span></Form.Label>
                                        {['radio'].map((type) => (
                                            <div key={`inline-${type}`} className="mb-3">
                                            <Form.Check
                                                
                                                label="Yes"
                                                name="check_project_manager"
                                                value="0"
                                                type={type}
                                                
                                                id="6"
                                                onChange={(e)=>setCheckProjectManager(e.target.value)}
                                                required
                                            />
                                            <Form.Check
                                                
                                                label="No"
                                                name="check_project_manager"
                                                value="1"
                                                type={type}
                                               
                                                id="7"
                                                onChange={(e)=>setCheckProjectManager(e.target.value)}
                                                required
                                            />
                                    
                                            </div>
                                        ))}
                                    </Form.Group> */}


                                    <Form.Label>Description (説明)<span className="text-danger"> *</span></Form.Label>
                                    
                                    <FloatingLabel controlId="floatingTextarea" className="mb-3">
                                        <Form.Control as="textarea" placeholder="Leave a comment here"  onChange={(e)=>setDescription(e.target.value)}  required/>
                                    </FloatingLabel>
                                
                                <Button variant="primary" onClick={apply_leave}>
                                    Submit
                                </Button>
                            </Form>

                                
                            </Card.Body>
                        </Card>
                    </div>


                </section>

            </div>















            <Footer/>
        </div>
    );
}

export default CasualLeaveApply;