import React, { useState, useEffect } from 'react'



import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import './admin.css';
import {Link, useHistory} from 'react-router-dom'
import { MDBCol } from "mdbreact"; 
import axios from 'axios';


import { Button, Form, FormGroup, Label, Input, Card, Table }
  from 'react-bootstrap';

import MaterialTable from "material-table";
//import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import { CsvBuilder } from "filefy";

function Employeesummary() {
   
  const token = localStorage.getItem("token")
  const emp_id = sessionStorage.getItem("session_emp_id")

      
  let loggedIn = true;

  const [items, setIteams] = useState([]);
  const [value, setValue] = useState(null);
  const [leav_item, setLeavIteam] = useState(null)

  const [isLoaded, setIsLoaded] = useState(false)
  
  

  const history = useHistory();

  var serial = 0;

  var today = new Date(),
  date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();


  //material datatable code starts here
  const columns = [
    // {
    //   title: "Serial", field: (rowIndex)=> {return rowIndex}, emptyValue: () => <em>null</em>,
    //    hidden: false,
    // },
    {
      title: "Employee name",
      field: "name",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },

    {
      title: "Start work",
      field: "start_work",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Start lunch",
      field: "start_lunch",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "End lunch",
      field: "end_lunch",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "End work",
      field: "end_work",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
  ];
  // custom export off
  // options for showing data in material table
  const options = {
    headerStyle: {
      backgroundColor: "grey", //'#80e5ff',
      color: "#000",
    },
    addRowPosition: "first",
    actionsColumnIndex: -1,
    columnsButton: true,
    exportButton: true,
    exportAllData: true,
    filtering: false,
    searchFieldVariant: "outlined",
    pageSizeOptions: [5, 10, 20, 50, 100],
    emptyRowsWhenPaging: false,
    paginationType: "stepped",
    jumpToPage: true,
    rowStyle: (data, index) =>
      index % 2 == 0
        ? { background: "#E2E3E5", wordWrap: "break-word" }
        : { background: "#D1D2D4", wordWrap: "break-word" },
        cellStyle: (data, rowData) => 
        rowData.start_work > "08:00:00"
        ? {background: '#ff8c66'}
        : null,
    headerStyle: {
      position: "sticky",
      top: "0",
      fontSize: "1.1rem",
      fontWeight: "500",
      width: "0rem",
      paddingLeft: "0px",
      paddingRight: "0px",
      backgroundColor: "#01579b",
      color: "white",
      //backgroundColor: "#039be5",
    },
  };
  //no action required
  //


  useEffect(() => {

    axios({
      method:'post',
      url:'http://localhost:8000/api/admin/showEmployeeActivity/', 
      data: {
        date : date             
      }
      
      })
      .then(response => { 
          // console.log(response.data.data)
          setIteams(response.data.data)
          // console.log(response.data.data.length)
          setLeavIteam(response.data.data.length)
         
          setIsLoaded(true)
      })

      .catch(error => {
          console.log(error)
      });

}, []);
    

        return (
            <div class="wrapper">
                <Header/>
                <Menu/>

               

                <div className="content-wrapper">                  
                  
                      {/* Main content */}
                      <section className="content">

                        
                          <div className="d-flex justify-content-center">
                            <Card border="danger" style={{ width: '100rem' }}>
                              <Card.Header className="text-danger text-center">
                                <h4>Today's Employee Attendance <br/> <h6>Date: {date}  </h6> </h4>
                                {/* <h6 className="text-danger text-right">Date:  {displaydate} </h6> */}
                              </Card.Header>
                              
                              <Card.Body>
                                <Link to="/employeeSummary">
                                  <button className="btn btn-primary btn-sm emp_btn" disabled="true"> All</button>
                                </Link>
                                <Link to="/lateEmployee">
                                  <button className="btn btn-primary btn-sm emp_btn"> Late In</button>
                                </Link> 
                                <Link to="/absentEmployee">
                                  <button className="btn btn-primary btn-sm emp_btn "> Absent</button>
                                </Link>
                                <Link to="/leaveEmployee">
                                  <button className="btn btn-primary btn-sm emp_btn"> Leave</button>
                                </Link>
                                <Link to="/dailyHealthIssue">
                                  <button className="btn btn-primary btn-sm emp_btn"> Health Issue</button>
                                </Link>
                                {/* <h4 className="text-danger text-right">Date:  {displaydate} </h4> */}

                              
                                  {leav_item == 0 ? 

                                        <h4 className="alert alert-info text-center"> No leave history available </h4>
                                        :
                                        <MaterialTable
                                          columns={columns}
                                          data={items}
                                          title="Today's employee activity"
                                          options={options}
                                          
                                        />
                                        }

                              </Card.Body>
                            </Card>
                          </div>


                        
                      </section>
                    {/* /.content */}
                </div>
                
                <Footer/>
                
            </div>
        )
  }
  export default Employeesummary;




