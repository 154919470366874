import React, { useState, useEffect } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Table,
} from "react-bootstrap";

import { Link, Redirect, useHistory } from "react-router-dom";

import axios from "axios";

///// material UI table import

import MaterialTable from "material-table";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
//import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import { CsvBuilder } from "filefy";

function Dashboard() {
  const token = localStorage.getItem("token");
  const emp_id = localStorage.getItem("emp_id");

  let loggedIn = true;

  const [items, setIteams] = useState([]);
  const [value, setValue] = useState(null);
  const [leav_item, setLeavIteam] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);

  const history = useHistory();

  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  // from maruf-san project
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
  const [selectedRows, setSelectedRows] = useState([]);

  //const [tableData, setTableData] = useState([]);
  let serial = 1;
  const columns = [
    // {
    //   title: "Serial", field: (rowIndex)=> {return rowIndex}, emptyValue: () => <em>null</em>,
    //    hidden: false,
    // },
    {
      title: "Role",
      field: "role",
      emptyValue: () => <em>null</em>,
      hidden: true,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },

    {
      title: "Name",
      field: "name",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Email",
      field: "email",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
    {
      title: "Phone number",
      field: "phone",
      emptyValue: () => <em>null</em>,
      headerStyle: { textAlign: "left", paddingLeft: "15px" },
    },
  ];
  const customRowExport = () => {
    new CsvBuilder("All Users Information.csv")
      .setColumns(columns.map((col) => col.title))
      .addRows(
        selectedRows.map((rowData) => columns.map((col) => rowData[col.field]))
      )
      .exportFile();
  };
  const options1 = {
    sorting: true,
    search: true,
    searchFieldAlignment: "right",
    searchAutoFocus: false,
    searchFieldVariant: "standard",
    filtering: false,
    emptyRowsWhenPaging: false,
    paging: true,
    pageSizeOptions: [10, 20, 50, 100],
    pageSize: 10,
    paginationType: "stepped",
    paginationPosition: "top",
    showFirstLastPageButtons: true,
    exportButton: true,
    exportAllData: true,
    addRowPosition: "first",
    actionsColumnIndex: -1,
    selection: true,
    showSelectAllCheckbox: true,
    selectionProps: (rowData) => ({
      color: "primary",
    }),
    grouping: false,
    columnsButton: true,
    rowStyle: (data, index) =>
      index % 2 == 0
        ? { background: null, wordWrap: "break-word" }
        : { background: "#EEFEFF", wordWrap: "break-word" },
    headerStyle: {
      position: "sticky",
      top: "0",
      fontSize: "1.1rem",
      fontWeight: "500",
      width: "0rem",
      paddingLeft: "0px",
      paddingRight: "0px",
      backgroundColor: "#ADEFD1FF",
      //backgroundColor: "#039be5",
    },
    maxBodyHeight: "70vh",
    tableLayout: "fixed",
  };

  const options = {
    addRowPosition: "first",
    actionsColumnIndex: -1,
    columnsButton: true,
    exportButton: true,
    exportAllData: true,
    filtering: false,
    searchFieldVariant: "outlined",
    pageSizeOptions: [5, 10, 20, 50, 100],
    emptyRowsWhenPaging: false,
    paginationType: "stepped",
    jumpToPage: true,
    rowStyle: (data, index) =>
      index % 2 == 0
        ? { background: "#E2E3E5", wordWrap: "break-word" }
        : { background: "#D1D2D4", wordWrap: "break-word" },
    headerStyle: {
      position: "sticky",
      top: "0",
      fontSize: "1.1rem",
      fontWeight: "500",
      width: "0rem",
      paddingLeft: "0px",
      paddingRight: "0px",
      backgroundColor: "#01579b",
      color: "white",
      //backgroundColor: "#039be5",
    },
  };
  const actions = [
    (rowData) => ({
      icon: ManageAccountsIcon,
      iconProps: {  fontSize: "large", color: "error"  },
      tooltip: "Marked as Manager",
      onClick: (event, rowData) => {
        const flag = window.confirm(
          "Aer you want to marked " + rowData.name + " as a manager"
        );
      },
      disabled: rowData.role == 4,
    }),
    (rowData) => ({
      icon: AdminPanelSettingsRoundedIcon,
      iconProps: {  fontSize: "large", color: 'yellow'  },
      tooltip: "Marked as Admin",
      onClick: (event, rowData) => {
        const flag = window.confirm(
          "Aer you want to marked " + rowData.name + " as a admin"
        );
      },
      disabled: rowData.role == 2,
    }),
  ];

  useEffect(() => {
    fetch("http://localhost:8000/api/admin/getUsers/")
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        setIteams(json.data);
        // setIteams(json.data)
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (today.getDate() < 10) {
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      "0" +
      today.getDate();
  }

  if (token == null) {
    loggedIn = false;
  }

  if (loggedIn === false) {
    return <Redirect to="/" />;
  }

  if (isLoaded === false) {
    return <h5>Loading....</h5>;
  } else {
    return (
      <div>
        <div className="content-wrapper">
          {/* Main content */}
          <section className="content">
            {/* <h2 className="alert alert-info">WELCOME TO ULTRA-X </h2> */}

            <div className="d-flex justify-content-center">
              <Card border="danger" style={{ width: "75rem" }}>
                <Card.Header className="text-danger text-center">
                  <h4>All Employee List </h4>
                </Card.Header>
                <Card.Body>
                  <MaterialTable
                    columns={columns}
                    data={items}
                    title="Employee List"
                    options={options}
                    actions={actions}
                  />
                </Card.Body>
              </Card>
            </div>
          </section>
          {/* /.content */}
        </div>
      </div>
    );
  }
}
export default Dashboard;
