
import React, { useState, useEffect } from 'react'

import Header from '../homepage/Header';
import Menu from '../homepage/Menu';
import Dashboard from '../homepage/Dashboard';
import Footer from '../homepage/Footer';
import { Redirect } from 'react-router';
import { ReactDOM } from 'react-dom';
import "./attendence.css"; 

import axios from 'axios'; 


import { Button, Form, FormGroup, Label, Input, Card, Table, InputGroup, FormControl }
  from 'react-bootstrap';



function EmployeeHealthIssue() {

    const token = localStorage.getItem("token")
    var emp_id =localStorage.getItem("emp_id");
        
    let loggedIn = true;

    
    
    const [health_apply_flag, setHealthApplyFlag] = useState(null)
    const [fever, setFever] = useState("");
    const [smell, setSmell] = useState("");
    const [headache, setHeadache] = useState("");
    const [cough, setCough] = useState("");
    const [muscle, setMuscle] = useState("");
    const [gargle, setGargle] = useState(""); 
    const [sleep, setSleep] = useState("");
    const [family, setFamily] = useState("");

    const [health_error, setHealthError] = useState("");
    

    // const history = useHistory();


    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    // console.log(date);

    if(today.getDate()<10){
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + '0'+ today.getDate();
        // console.log(date);
    }


    useEffect(() => {

        axios({
          method:'post',
          url:'http://localhost:8000/api/users/healthInfoSubmittedOrNot/', 
          data: {
            emp_id: emp_id, date: date             
          }
          
          })
          .then(response => { 
            //   console.log("thik ase")
            //   console.log(response.data.success)
              
                if(response.data.success == 1){
                    console.log("already submit korsen")
                    setHealthApplyFlag(1)
                }
                else{
                    // console.log(" submit korte hohe")
                    setHealthApplyFlag(0)
                }
          })
    
          .catch(error => {
              console.log(error)
          });
    
    }, []);



    async function heath_submit()
    {
        // console.log("ok vai")
        // console.log(fever, smell, headache, cough, muscle, gargle, sleep, family)


        axios({ 
            method:'post',
            url:'http://localhost:8000/api/users/insertHealthInfo',
            data: {
                emp_id: emp_id,
                date : date,
                fever: fever,
                less_smell : smell,
                headache: headache,
                cough: cough,
                muscle_aches: muscle,
                gurgle_frequently: gargle,
                enough_sleep: sleep,
                family_info: family
            }
            
        })
        .then(response => {
            if(response.data.success == 1){
                setHealthError(response.data.message)
                window.location.reload(false);
            }
            else{
                setHealthError(response.data.message)
                window.location.reload(false);
            }
        })
        .catch(err=>console.error(err));
    }


    if(health_error){
        var error = (
            <p className="text-danger" role="alert">* {health_error}</p>
        )
    }




    if(token == null){
        loggedIn = false
    }


    if(loggedIn === false){
        return <Redirect to="/" />
    }



   
        return (
            <div class="wrapper">
                <Header/>
                <Menu/>

                {/* <h2 className="text-success date-content"> Date : {displaydate} </h2> */}

                <div className="content-wrapper d-flex justify-content-center ">                  
                    <section className="content">
                    {health_apply_flag == 1 ? 

                            <Card border="secondary" style={{ width: '30rem' }}>
                                <Card.Header><h3 className="text-danger text-center">Health Check of ULTRA-X</h3></Card.Header>
                                <Card.Body>
                                    
                                    <h5 className="alert alert-danger text-center">You already submittted today.</h5>
                                
                                </Card.Body>
                            </Card>

                    :

                    <Card border="secondary" style={{ width: '30rem' }}>
                        <Card.Header><h3 className="text-danger text-center">Health Check of ULTRA-X</h3></Card.Header>
                        <Card.Body>
                                                        
                        <Form>
                             
                            <Form.Group className="mb-3" controlId="formBasicEmail1">
                                <Form.Label>Do you have a fever? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div  className="mb-3">
                                        <Form.Check
                                            
                                            label="Yes"
                                            name="fever"
                                            value = "1"
                                            type={type}
                                            onChange={(e)=>setFever(e.target.value)}
                                            id="1"
                                            
                                            required
                                        />
                                        <Form.Check
                                        
                                            label="No"
                                            name="fever"
                                            value="0"
                                            type={type}
                                            onChange={(e)=>setFever(e.target.value)}
                                            id="2"
                                            
                                            required
                                        />                        
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail2">
                                <Form.Label>Do you have a loss of smell or taste ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        
                                        label="Yes"
                                        name="smell"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setSmell(e.target.value)}
                                        id="3"
                                    
                                        required
                                    />
                                    <Form.Check
                                    
                                        label="No"
                                        name="smell"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setSmell(e.target.value)}
                                        id="4"
                                    
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail3">
                                <Form.Label>Do you have a new or unusual headache ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                    
                                        label="Yes"
                                        name="headache"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setHeadache(e.target.value)}
                                        id="5"
                                        
                                        required
                                    />
                                    <Form.Check
                                        
                                        label="No"
                                        name="headache"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setHeadache(e.target.value)}
                                        id="6"
                                        
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail4">
                                <Form.Label>Do you have a cough ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                    
                                        label="Yes"
                                        name="cough"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setCough(e.target.value)}
                                        id="7"
                                    
                                        required
                                    />
                                    <Form.Check
                                    
                                        label="No"
                                        name="cough"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setCough(e.target.value)}
                                        id="8"
                                        
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail5">
                                <Form.Label>Do you have muscle aches ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        
                                        label="Yes"
                                        name="muscle"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setMuscle(e.target.value)}
                                        id="9"
                                    
                                        required
                                    />
                                    <Form.Check
                                    
                                        label="No"
                                        name="muscle"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setMuscle(e.target.value)}
                                        id="10"
                                    
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicEmail6">
                                <Form.Label>Do you wash your hand and gargle frequently ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                    
                                        label="Yes"
                                        name="gargle"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setGargle(e.target.value)}
                                        id="11"
                                        
                                        required
                                    />
                                    <Form.Check
                                    
                                        label="No"
                                        name="gargle"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setGargle(e.target.value)}
                                        id="12"
                                       
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail7">
                                <Form.Label>Do you get enough sleep ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                    
                                        label="Yes"
                                        name="sleep"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setSleep(e.target.value)}
                                        id="13"
                                        
                                        required
                                    />
                                    <Form.Check
                                        
                                        label="No"
                                        name="sleep"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setSleep(e.target.value)}
                                        id="14"
                                       
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail8">
                                <Form.Label>Does anyone in your family have a fever cough, or taste disorder ? <span className="text-danger"> *</span></Form.Label>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                    
                                        label="Yes"
                                        name="family"
                                        value="1"
                                        type={type}
                                        onChange={(e)=>setFamily(e.target.value)}
                                        id="15"
                                        
                                        required
                                    />
                                    <Form.Check
                                    
                                        label="No"
                                        name="family"
                                        value="0"
                                        type={type}
                                        onChange={(e)=>setFamily(e.target.value)}
                                        id="16"
                                       
                                        required
                                    />
                            
                                    </div>
                                ))}
                            </Form.Group>
                            
                            {error}

                            <Button variant="primary" onClick={heath_submit}>
                                Submit
                            </Button>
                            
                        </Form>

                        </Card.Body>
                    </Card>
                    }       

                    </section>
                
                </div>


              <Footer/>
                
            </div>
        )
    }
    export default EmployeeHealthIssue;