import React, { useState, useEffect } from 'react'

import "./login.css"
import "../homepage/homepage"

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { Button, Form, FormGroup, Label, Input, Card, DropdownButton, Dropdown }
  from 'react-bootstrap';

import {Link, Redirect, useHistory} from 'react-router-dom';

import axios from 'axios';



      
function Login() {

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null);
    const [role, setRole] = useState(null)

    const [loggedIn, setloggedIn] = useState(false);

    const [loginError, setloginError] = useState(null);

    const [login_role, setLoginRole] = useState(null);

    const history = useHistory();

    




    async function submit_login()
    {
        // console.log(role, email, password)

        // let item ={email, password};

        axios({
            method:'post',
            url:'http://localhost:8000/api/users/login/', 
            data: {
                email : email,
                password : password,
                role: role 
            }
            
        })
        .then(response => { 

            if(response.data.success == 1){

                // console.log(response.data)
                
                localStorage.setItem("token", response.data.token);
    
                localStorage.setItem("emp_id", response.data.employee_id);
    
                localStorage.setItem("emp_name", response.data.employee_name);
    
                localStorage.setItem("emp_role", response.data.role);

                setloggedIn(true)

                setLoginRole(1)
                console.log(login_role)
                localStorage.setItem("login_role", 1)
                // console.log("you are employee")
                // return <Redirect to="./attendence"/>
                
            }
            else if(response.data.success == 2){

                console.log(response.data)
                
                localStorage.setItem("token", response.data.token);
    
                localStorage.setItem("emp_id", response.data.employee_id);
    
                localStorage.setItem("emp_name", response.data.employee_name);
    
                localStorage.setItem("emp_role", response.data.role);

                setloggedIn(true)

                setLoginRole(2)
                localStorage.setItem("login_role", 2)
                // console.log("you are Admin")
                // return <Redirect to="./employeeSummary"/>
                
            }

            else if(response.data.success == 4){

                console.log(response.data)
                
                localStorage.setItem("token", response.data.token);
    
                localStorage.setItem("emp_id", response.data.employee_id);
    
                localStorage.setItem("emp_name", response.data.employee_name);
    
                localStorage.setItem("emp_role", response.data.role);

                setloggedIn(true)

                setLoginRole(4)

                localStorage.setItem("login_role", 4)
                // console.log("you are manager")
                // return <h2 className="alert alert-info "> You are Manager</h2>
                
            }
    
            else{
                setloggedIn(false)
                setloginError(response.data.message)
                // console.log("you are shit")
                // return <h2 className="alert alert-info "> You are </h2>
            }
        })
        .catch(error => {
            console.log(error)
        });
    }




    if(loggedIn){
        

        if(login_role == 2){
            // console.log("admin")
            return <Redirect to="./employeeSummary"/>
        }
        else if(login_role == 1)
        {
            // console.log("employeee")
            return <Redirect to="./attendence"/>
        }
        else if(login_role == 4)
        {
            // return <h2 className="alert alert-info "> You are Manager</h2> 
            return <Redirect to="./employeeSummary"/>
        }
    }

    
    if(loginError){
        var error = (
            <p className="text-danger" role="alert">* {loginError}</p>
        )
    }
   

    return (

        
        <div className="wrapper">
            <div className="d-flex justify-content-center">
                        <Card Card border="primary" className="bg-light login-content" style={{ width: '35rem' }}>
                            <img src="../dist/img/UXBD_logo.png"  alt="User Image"/>
                            <h3 className="text-center">LOGIN (ログインする)</h3> 

                            <Card.Body>

                            {/* {console.log(login_role)} */}

                            <Form> 
                                <Form.Group className="mb-3" >
                                    <Form.Label>Role : &nbsp; &nbsp;  </Form.Label>
                                        <select name="role" id="role" onChange={(e)=>setRole(e.target.value)}>
                                            <option>Chosse One</option>
                                            <option value="2" >Admin</option>
                                            <option value="4" >Manager</option>
                                            <option value="1">Employee</option>
                                        </select>    <b className="text-danger ">*</b>                          
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)}/>                                   
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password <b className="text-danger ">*</b> </Form.Label>
                                    <Form.Control type="password" placeholder="Enter Password" onChange={(e)=>setPassword(e.target.value)}/>
                                </Form.Group>

                                {error}

                                <Button variant="primary" onClick={submit_login}>
                                    Submit
                                </Button>

                            </Form>

                           

                            <p className="custom-link"> Do you haven't an account? please (アカウントをお持ちではありませんか？お願いします) <Link to="./register/register" className="custom-link">register (登録)</Link> </p>
                                
                            </Card.Body>
                        </Card>
            </div>
        </div>
    )
}
export default Login;